.city-border {
    margin: 10px;
    padding: 10px;
    border-style: solid;
    border-color: rgb(54, 54, 54);
    border-radius: 15px;
    border-width: 2px;
}

.city-border:hover {
    transform:scale(1.007,1.007);
    background-color: rgb(248, 248, 248);
    box-shadow:2px 2px 2px 3px rgb(218, 218, 218)
}

.mobile-city-border {
    margin: 10px;
    padding: 10px;
    border-style: solid;
    border-color: rgb(54, 54, 54);
    border-radius: 15px;
    border-width: 2px;
}

.city-name-and-buttons{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}

.city-name {
    color:rgb(54, 54, 54);
    font-family:'Quicksand';
    font-size: 40px;
}
@media only screen and (max-width: 767.5px) {
    .city-name {
        font-size: 25px;
    }
}

.saved-city-buttons{
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.saved-city-remove{
    transform:scale(1.4,1.4);
    margin-left:12px;
    margin-top:8px;
    margin-right:25px;
    color: black;
    height: 10px;
}

.saved-city-remove:hover{
    color: red;
    transform:scale(1.35,1.35);
}

.saved-city-expand{
    transform:scale(1.3,1.3);
    margin-top:8px;
    margin-right:10px;
    color: black;
    height: 10px;
}

.saved-city-expand:hover{
    color: orange;
    transform:scale(1.28,1.28);
}

#save-city-button {
    margin-right:10px;
    margin-left:10px;
    margin-top:4px;
    width:27px;
    height:27px;
    color:orange;
    text-align: center;
}

#save-city-button:hover {
    transform:scale(1.15,1.15);
}

#save-city-button:active {
    transform:scale(1,1);
}

.saved-city-temp-description{
    display:flex;
    flex-direction: row;
    align-items: center;
}

.temp {
    color:orange;
    font-family:'Quicksand';
    font-size: 30px;
}

.description {
    color:rgb(54, 54, 54);
    font-family:'Quicksand';
    font-size: 20px;
    margin-left:20px;
}

.degree {
    color:rgb(54, 54, 54);
    font-family:'Quicksand';
    font-size: 16px;
}

.icon{
    width:50px;
    height:50px;
    border-radius:45px;
    margin: 28px;
    background-color: rgb(184, 184, 184);
}