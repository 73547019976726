
.pac-item-query{
    font-family:'Quicksand';
    font-size:18px;
}

.pac-item{
    font-family:'Quicksand';
    font-size:15px;

}

.pac-icon{
    display:none;
}