#search-input-and-submit{
  margin-left: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.brand{
  height:18px;
  width:25px;
  margin-right:10px;
  animation: sampleanimation 6s infinite;
}

@keyframes sampleanimation {
  0% {
    transform: scale(1,1);
  }
  12.5% {
  }
  25% {
    transform: scale(1.15,1.15);
  }
  37.5% {
  }
  50% {
    transform: scale(1,1);
  }
  62.5% {
  }
  75% {
    transform: scale(1.15,1.15);
  }
  87.5% {
    transform: rotate(360deg);
  }
}