.wind-values{
    display: flex;
    flex-direction: row;
    align-content: baseline;
}

/* the direction of the wind arrow is set by an inline style in DayDetails */
.wind-arrow{
    padding-left: 5px;
    padding-top: 3px;
}

.day-details-date-and-description{
    margin-top:20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.day-details-date{
    font-family:'Quicksand';
    color:rgb(175, 175, 175);
}

.day-details-description{
    margin-left:10px;
    font-family:'Quicksand';
    color:orange;
}

.day-details-value{
    margin-left:10px;
    font-family:'Quicksand';
    color:rgb(107, 107, 107);
}

.day-details-columns{
    margin-top:15px;
    display:flex;
    flex-direction: row;
    /* justify-content: space-around; */

}

.day-details-key{
    font-family:'Quicksand';
    color:black;
}

.day-details-kvps{
    width:50%;
    display:flex;
    flex-direction:row;
}