#top-level-container {
    padding-top:100px;
    padding-bottom:200px;
}

#no-saved-weather-tip{
    text-align: center;
    padding-top: 150px;
    margin: auto;
    width: 50%;
}



