.mobile-day-snippet{
    flex: 1;
    text-align: center;
    display: inline-block;
    float: none;
    margin-left:15px;
    margin-right:15px;
    margin-bottom:13px;
}

.mobile-day-snippet:focus,
.mobile-day-snippet:hover,
.mobile-day-snippet:active{
    animation: sampleanimation2 .4s;
}

@keyframes sampleanimation2 {
    0% {
      transform: scale(1,1);
    }
    50% {
      transform: scale(1.2,1.2);
    }
}

.day-snippet{
    flex: 1;
    text-align: center;
    transform:scaleX(1.01);
}

.day-snippet:hover{
    /* transform:scale(1.1,1.1); */
    cursor:pointer;
    border-bottom-style: solid;
    border-left-style: none;
    border-bottom-width:2px;
    border-bottom-color:orange;
    margin-top:2px;
    margin-bottom:-6px;
}

.day-snippet:active{
    transform:scale(1,1);
    border-bottom-style: solid;
    border-left-style: none;
    border-bottom-width:2px;
    border-bottom-color:orange;
    margin-bottom:-4px;
}

.daily-icon{
    flex:1;
    border-radius:45px;
    width: 5vw;
    height: 5vw;
    min-width: 40px;
    min-height: 40px;
    max-width: 60px;
    max-height: 60px;
    background-color: rgb(184, 184, 184);
}

.daily-snippet-high-low{
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.daily-snippet-high{
    font-family:'Quicksand';
    font-size: 15px;
    color:black;
}

.daily-snippet-low{
    font-family:'Quicksand';
    font-size: 15px;
    color:gray;
}
