.hour{
    flex: 1;
    text-align: center;
    transform:scaleX(1.01);
}

.hour:hover{
    /* transform:scale(1.1,1.1); */
    cursor:pointer;

    border-bottom-style: solid;
    /* border-left-style: none; */
    border-bottom-width:2px;
    border-bottom-color:orange;

    margin-top:2px;
    margin-bottom:-6px;
}

.hour:active{
    transform:scale(1,1);
    border-bottom-style: solid;
    border-left-style: none;
    border-bottom-width:2px;
    border-bottom-color:orange;
    margin-bottom:-4px;
}

.mobile-hour{
    flex: 1;
    text-align: center;
    display: inline-block;
    float: none;
    padding-left:24px;
    padding-right:24px;

    margin-bottom:13px;
}

.mobile-hour:focus,
.mobile-hour:hover,
.mobile-hour:active{
    animation: sampleanimation2 .4s;
    /* cursor:pointer;

    border-bottom-style: solid;
    border-left-style: none;
    border-bottom-width:2px;
    border-bottom-color:orange;

    margin-top:2px;
    margin-bottom:-6px; */
}

.midnight-hour{
    border-left: 2px solid;
}

.hour-time{
    margin-bottom: 10px;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items:baseline;
}

.midnight-hour-time-int{
    color:orange;
}

.midnight-hour-time-am-pm{
    color:orange;
    font-size:11px;
}

/* #hour-time-int{
} */

.hour-time-am-pm{
    font-size:11px;
}

.hour-temp-degree {
    color:rgb(54, 54, 54);
    font-family:'Quicksand';
    font-size: 16px;
}

.hour-temp-unit {
    color: black;
    font-family:'Quicksand';
    font-size: 10px;
}