.forecast-control{
    width:28px;
    height:28px;
    /* border-style: solid;
    border-width: 1px;
    border-radius: 9px; */
    margin-top: 14px;
    margin-left:10px;
    margin-right:10px;

}
.forecast-control:hover{
    transform: scale(1.1,1.1);
    /* box-shadow: 0px 0px .5px .5px rgb(206, 206, 206); */
    color:orange;
    cursor:pointer;

}

.forecast-control:active{
    transform: scale(1,1);
    box-shadow: 0px 0px white;
}

.mobile-hourly-forecast{
    overflow-x: scroll;
    white-space: nowrap;
}